form.feedback-form-wrapper {
  top: 15vh;
  left: 10vw;
  right: 10vw;
}

.checkbox-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;

  & > .checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #00ffc2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: #00ffc2;
    font-size: 18px;
    &.active {
      border: 2px solid #00ffc2;
    }
  }

  & > .text {
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    color: white;
  }
}
