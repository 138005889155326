button.button-variant {
  border-radius: 33px;

  font-family: Koulen;
  font-size: 24px;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: center;
  border-color: transparent;
  &-primary {
    background-color: #00ffc2;
    box-shadow: #00ffc2 0px 0px 5px 2px;
    color: #03021f;

    transition: background-color 0.2s ease, box-shadow 0.2s ease;

    &.disabled {
      background: #00ffc255;
      box-shadow: #00ffc2 0px 0px 0px 0px;

      transition: background-color 0s ease, box-shadow 0s ease;
    }
  }
}
