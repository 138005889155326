div.progress-page-info-bubble-wrapper {
  top: 5vh;
  bottom: 60vh;
  width: 100vw;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  & > div.bubble {
    height: 17vh;
    width: 17vh;

    background: #00ffc2;

    box-shadow: 0px 0px 20px #00ffc2;
    border-radius: 20vh;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Koulen;
    font-size: 96px;
    font-weight: 400;
    line-height: 173px;
    letter-spacing: 0em;
    text-align: center;
    color: #03021f;
  }

  & > p {
    font-family: Koulen;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #7000ff;
  }
}
