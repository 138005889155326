div.challenge-info-wrapper {
  width: 80vw;
  top: 30vh;
  bottom: 35vh;
  left: 10vw;

  @media (max-height: 700px) {
    width: 90vw;
    left: 5vw;
  }

  & > p {
    font-family: Quicksand;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
  }
}

div.challenge-info-image-wrapper {
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  height: 30vh;
}
