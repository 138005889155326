.authenticate-button {
  top: 40vh;
  left: 20vw;
  right: 20vw;

  @media (max-width: 300px) {
    left: 10vw;
    right: 10vw;
  }
}

.register-wrapper {
  top: 53vh;
  left: 10vw;
  right: 10vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;

  @media (max-width: 300px) {
    left: 8vw;
    right: 8vw;
  }

  & > p {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0;
  }

  & > a {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #00ffc2;
    text-decoration: underline;
  }
}


