.authenticate-input {
  top: 25vh;
  left: 8vw;
  right: 8vw;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 300px) {
    top: 22vh;
  }

  @media (max-height: 700px) {
    top: 22vh;
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-120%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(120%);
    opacity: 0;
  }
}
.retry-wrapper {
  top: 45vh;
  left: 20vw;
  right: 20vw;
  transition: top 0.1s;

  @media (max-height: 550px) {
    top: 70vh;
    transition: top 0.1s;
  }

  &.disappearing {
    & > p:first-child {
      animation: slideToRight 0.5s ease-out forwards;
    }

    & > p:nth-child(2) {
      animation: slideToRight 0.5s 0.2s ease-out forwards;
    }
  }

  & > p:first-child {
    font-family: Quicksand;
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;

    animation: slideFromLeft 0.8s ease-out backwards;
  }

  & > p:nth-child(2) {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #00ffc2;
    text-decoration: underline;
    animation: slideFromLeft 0.8s 0.5s ease-out backwards;
  }
}

.different-account {
  top: 60vh;
  left: 20vw;
  right: 20vw;
  display: flex;
  justify-content: center;

  transition: top 0.1s;

  @media (max-height: 550px) {
    top: 85vh;
    transition: top 0.1s;
  }

  & > a {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
  }
}

div.verify-mascot {
  bottom: -10px;
  height: 35vh;
  display: flex;
  justify-content: center;
  width: 100vw;
  transition: opacity 0.1s;

  &.short {
    height: 30vh;
  }

  @media (max-height: 550px) {
    opacity: 0;
    transition: opacity 0.1s;
  }
}
