.learn-content-element-wrapper {
  width: 100%;

  & > img {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  & > p {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
  }
}
