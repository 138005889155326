div.about-wrapper {
  top: 10vh;
  left: 5vw;
  right: 8vw;
  bottom: 8vh;

  & h2 {
    font-family: Koulen;
    font-size: 30px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0em;
    text-align: center;
    color: #ffa300;
  }

  & p {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: justify;
    color: white;
  }
}
