@keyframes challengeNotificationClosing {
  0% {
    transform: scaleY(1);
    transform: translateY(0);
  }

  100% {
    transform: scaleY(0);
    transform: translateY(-100%);
  }
}

div.challenge-notification {
  top: 1vh;
  left: 3vw;
  right: 3vw;
  height: 18vh;

  @media (max-height: 700px) {
    top: 1vh;
    left: 1.5vw;
    right: 1.5vw;
    height: 15vh;
  }

  border-bottom: 1px solid #ffffff44;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  & > .info {
    display: flex;
    width: 90%;
    align-items: center;

    @media (max-height: 700px) {
      width: 90%;
    }

    & > img {
      flex: 1;
      height: 90px;

      @media (max-height: 700px) {
        height: 80px;
      }
    }

    & > div {
      flex: 2;

      font-family: Quicksand;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: #ffffff;

      @media (max-height: 700px) {
        flex: 3;
        font-size: 15px;
      }
    }
  }

  & > .interaction {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: Koulen;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffa300;

    gap: 20px;

    & img {
      height: 40px;
      margin-right: 20px;

      @media (max-height: 700px) {
        height: 35px;
      }
    }

    @media (max-height: 700px) {
      font-size: 18px;
      gap: 15px;
    }
  }

  & > .close-button {
    top: 1vh;
    right: 1vh;
  }

  &.closing {
    animation: challengeNotificationClosing 0.5s forwards;
  }
}

div.challenge-notification-page {
  display: flex;
  width: 90%;
  flex-direction: row;
  align-items: center;
  margin-bottom: -10px;

  & > img {
    flex: 1;
    height: 80px;
  }

  & > div {
    flex: 3;
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;

    color: #ffffff;
  }
}

div.calendar-notification {
  bottom: 60vh;
  height: 10vh;
  left: 2vw;
  right: 2vw;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media (max-height: 700px) {
    left: 0.5vw;
    right: 0.5vw;
  }

  & > .info {
    display: flex;
    width: 90%;
    align-items: center;

    & > img {
      flex: 1;
      height: 90px;

      @media (max-height: 700px) {
        height: 80px;
      }
    }

    & > div {
      flex: 3;

      font-family: Quicksand;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #ffffff;

      @media (max-height: 700px) {
        flex: 4;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  & > .open-button {
    bottom: -1vh;
    right: 3vh;
  }

  & > .date {
    top: -0.5vh;
    right: 2vh;
    font-family: Quicksand;
    font-size: 11px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff44;

    @media (max-height: 700px) {
      top: -1vh;
      font-size: 10px;
      line-height: 24px;
    }
  }
}

.calendar-notification-page {
  display: flex;
  flex-direction: column;
  width: 90%;

  & > .date-page {
    display: flex;
    justify-content: flex-end;
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff44;
  }

  & > .info {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: -10px;

    & > img {
      flex: 1;
      height: 90px;

      @media (max-height: 700px) {
        height: 80px;
      }
    }

    & > div {
      flex: 3;

      font-family: Quicksand;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: #ffffff;

      @media (max-height: 700px) {
        flex: 4;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.offline-notification {
  top: 0;
  bottom: 10vh;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  z-index: 1000;

  & > p {
    font-family: Koulen;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffa300;
  }
}
