.go-install-logo {
  width: 15vh;
  height: 15vh;
  left: 2vh;
  top: 2vh;

  @media (max-width: 768px) {
    width: 12vh;
    height: 12vh;
  }

  @media (max-width: 300px) {
    width: 18vw;
    height: 18vw;
  }
}

.install-hidden {
  opacity: 0;

  transition: opacity 0.5s;
}

div.install-wrapper {
  top: 10vh;
  bottom: 10vh;
  left: 25vw;
  right: 25vw;

  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;

  @media (max-width: 768px) {
    top: 6vh;
    left: 10vw;
    right: 10vw;
    bottom: 5vh;

    gap: 5px;
  }

  & > img.go-install-mascot {
    height: 40%;

    @media (max-width: 768px) {
      height: 30%;
    }
  }

  & > h1 {
    font-family: Koulen;
    font-size: 96px;
    font-weight: 400;
    line-height: 173px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffa300;

    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 40px;
    }
  }

  & > h3 {
    font-family: Raleway;
    font-size: 40px;
    font-weight: 400;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;

    &.mobile {
      margin-top: 5px;
      @media (min-width: 768px) {
        display: none;
      }
    }

    &.pc {
      display: none;
      @media (min-width: 768px) {
        display: inline;
      }
    }

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 23px;
    }
  }

  & > .install-button {
    width: 80%;

    @media (min-width: 768px) {
      display: none;
    }
  }

  & > div.buttons {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 5px;
    }

    & > a {
      text-decoration: none;
      width: 25%;

      @media (max-width: 768px) {
        width: 50%;
      }

      & > img {
        width: 100%;
      }
    }
  }

  & > div.footer {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    & > span {
      font-family: Raleway;
      font-size: 24px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff55;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 16px;
      }

      &.mobile {
        margin-top: 5px;
        @media (min-width: 768px) {
          display: none;
        }
      }

      & > a {
        color: inherit;
        text-decoration: none;
      }

      &:first-child {
        border-right: 1px solid #ffffff55;
        padding-right: 10px;

        @media (max-width: 768px) {
          border-right: 0;
          padding-right: 0;
        }
      }
      &:nth-child(2) {
        margin-left: 10px;

        @media (max-width: 768px) {
          margin-left: 0;
        }
      }
    }
  }
}
