html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #03021f !important;
  overflow-y: hidden;
  overflow-x: hidden;
}

div:not(.no-bg) {
  background: #03021f;
}

div.absolute,
form.absolute,
button.absolute,
img.absolute {
  position: absolute;
}

div.relative,
form.relative,
button.relative {
  position: relative;
}

.top-20 {
  top: 20vh;
}

.z-top-1 {
  z-index: 10;
}

.z-top-1:not(.absolute) {
  position: relative;
}

.z-top-2 {
  z-index: 20;
}

.z-top-3 {
  z-index: 30;
}

.z-top-4 {
  z-index: 40;
}

.z-top-2:not(.absolute) {
  position: relative;
}

@font-face {
  font-family: "Indie Flower";
  src: url("../public/assets/fonts/indie-flower.ttf") format("truetype");
}

@font-face {
  font-family: "Koulen";
  src: url("../public/assets/fonts/koulen.ttf") format("truetype");
}

@font-face {
  font-family: "Quicksand";
  src: url("../public/assets/fonts/quicksand.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway";
  src: url("../public/assets/fonts/raleway.ttf") format("truetype");
}
