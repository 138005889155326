.completed-by-scroll-content {
  top: 15vh;
  bottom: 10vh;
  left: 5vw;
  right: 5vw;

  @media (max-height: 700px) {
    bottom: 12vh;
    top: 12vh;
  }

  @media (max-width: 300px) {
    top: 15vh;
  }
}
