div.challenge-wrapper {
  top: 7vh;
  left: 4vw;
  right: 4vw;
  bottom: 20vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-height: 700px) {
    top: 4vh;
    bottom: 23vh;
  }

  & > p.description {
    font-family: Quicksand;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffa300;
    width: 75%;

    @media (max-width: 300px) {
      width: 85%;
    }
  }

  & > div.completed-by {
    width: 75%;

    font-family: Quicksand;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;

    & > a {
      font-family: Quicksand;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: center;
      text-decoration: underline;
      color: inherit;
    }
  }

  &.has_notification {
    top: 21vh;
    bottom: 25vh;

    @media (max-height: 700px) {
      top: 20vh;

      & > p.description {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0;
      }

      & > div.completed-by {
        margin-top: 4px;
      }
    }

    transition: top 0.5s, bottom 0.5s;
  }

  transition: top 0.5s, bottom 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.challenge-settings {
  top: 2vh;
  right: 2vh;

  z-index: 20;

  &.appearing {
    animation: fadeIn 0.5s forwards;
  }
}

div.challenge-completed-confetti-wrapper {
  top: 10vh;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  @media (max-height: 700px) {
    top: 5vh;
  }
}

div.challenge-completed-check-wrapper {
  &:not(.post-page) {
    top: 40vh;
    bottom: 50vh;
    width: 100%;
    @media (max-height: 700px) {
      top: 45vh;
    }
  }

  &.post-page {
    top: 5vh;
    right: 12vw;
    width: 10vh;

    transform: rotate(30deg);

    & > div {
      box-shadow: 0px 0px 25px #ffa300;
    }

    @media (max-width: 300px) {
      top: 11vh;
      right: 10vw;
    }
  }

  display: flex;
  justify-content: center;

  & > div {
    position: relative;
    width: 10vh;
    height: 10vh;
    background-color: #ffa300;
    border-radius: 5vh;

    @media (max-height: 700px) {
      width: 8vh;
      height: 8vh;
    }
    & > img {
      position: absolute;
      top: -25%;
      left: 25%;
      height: 100%;
    }
  }
}

div.challenge-completed-invite-wrapper {
  bottom: 12vh;
  left: 15vw;
  right: 15vw;

  & > p {
    font-family: Koulen;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #00ffc2;

    @media (max-height: 700px) {
      line-height: 26px;
    }
  }
}

div.challenge-completed-variant-confetti {
  top: 2vh;
  left: 10vw;
  right: 10vw;
  height: 25vh;
  display: flex;
  justify-content: center;

  @media (max-width: 300px) {
    height: 20vh;
  }
}

div.challenge-completed-variant-share {
  top: 58vh;
  left: 10vw;
  right: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 300px) {
    top: 53vh;
  }

  & > h3 {
    font-family: Koulen;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
  }
}

div.challenge-completed-variant-mascot-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  height: 25vh;
  display: flex;
  justify-content: center;

  & > img {
    height: 100%;
  }
}

.challenge-post-completed-scroll-content {
  top: 22vh;
  bottom: 12vh;
  left: 6vw;
  right: 6vw;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  gap: 35px;

  & > .review {
    font-family: Quicksand;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: underline;
    color: #ffffff;
  }
  & > .heading-2 {
    width: 70%;
    text-align: center;

    font-family: Koulen;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #00ffc2;
  }

  & > .hr {
    width: 70%;
    border-bottom: 1px solid gray;
  }

  & > .logo {
    font-family: Koulen;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffa300;
  }
}

div.challenge-review-check-wrapper {
  top: 10vh;
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  transform: rotate(10deg);

  & > div {
    position: relative;
    width: 10vh;
    height: 10vh;
    background-color: #ffa300;
    box-shadow: 0px 0px 25px #ffa300;
    border-radius: 5vh;

    @media (max-height: 700px) {
      width: 8vh;
      height: 8vh;
    }
    & > img {
      position: absolute;
      top: -25%;
      left: 25%;
      height: 100%;
    }
  }
}

div.challenge-review-info {
  top: 30vh;
  left: 10vw;
  right: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  & > .info {
    font-family: Quicksand;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffa300;
  }

  & > div.completed-by {
    width: 75%;

    font-family: Quicksand;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;

    & > a {
      font-family: Quicksand;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: center;
      text-decoration: underline;
      color: inherit;
    }
  }
}

/* CHALLENGE ANIMATION */

.challenge-page-bounce-in {
  animation: challengeBounceIn 1s forwards;
}
.challenge-animation-wrapper {
  top: 15vh;
  bottom: 0vh;
  left: 0vw;
  right: 0vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.challenge-animation-wrapper img {
  &:not(.hidden) {
    z-index: 100;
    &:nth-child(1) {
      margin-bottom: -15px;
      width: 75%;
    }

    &:nth-child(2) {
      margin-top: -15px;
      width: 55%;
    }
  }

  &.hidden {
    position: absolute;
    z-index: 90;
  }
}

.challenge-animation-wrapper.shaking > img {
  &:nth-child(1) {
    animation: bounce1 1s infinite;
    transform-origin: center bottom;
  }

  &:nth-child(2) {
    animation: bounce1 1s infinite;
    transform-origin: center 0px;
  }

  &.hidden {
    opacity: 0;
  }
}

.challenge-animation-wrapper.fading > img {
  &:nth-child(1) {
    animation: topMoveOut 1s forwards;
  }
  &:nth-child(2) {
    animation: baseMoveOut 1s forwards;
  }
  &:nth-child(3) {
    animation: confetti1MoveOut 2s forwards;
  }
  &:nth-child(4) {
    animation: confetti2MoveOut 2s forwards;
  }
  &:nth-child(5) {
    animation: confetti3MoveOut 2s forwards;
  }
  &:nth-child(6) {
    animation: confetti4MoveOut 2s forwards;
  }
  &:nth-child(7) {
    animation: confetti5MoveOut 2s forwards;
  }
  &:nth-child(8) {
    animation: confetti6MoveOut 2s forwards;
  }
  &:nth-child(9) {
    animation: confetti7MoveOut 2s forwards;
  }
  &:nth-child(10) {
    animation: confetti8MoveOut 2s forwards;
  }
  &:nth-child(11) {
    animation: confetti9MoveOut 2s forwards;
  }
  &:nth-child(12) {
    animation: confetti10MoveOut 2s forwards;
  }

  &.hidden {
    opacity: 0;
  }
}

@keyframes bounce1 {
  from,
  25%,
  60%,
  to {
    animation-timing-function: cubic-bezier(0.3, 0.6, 0.4, 1);
    transform: translate3d(0, 0, 0);
  }

  45%,
  50% {
    animation-timing-function: cubic-bezier(0.6, 0.2, 0.8, 0.3);
    transform: translate3d(0, -30px, 0) scaleY(1.1);
  }

  75% {
    animation-timing-function: cubic-bezier(0.6, 0.2, 0.8, 0.3);
    transform: translate3d(0, -15px, 0) scaleY(1.05);
  }

  85% {
    transition-timing-function: cubic-bezier(0.3, 0.6, 0.4, 1);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  95% {
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}

@keyframes topMoveOut {
  0% {
    transform: translate3d(0, 0, 0) rotate(0) scale(1);
    opacity: 1;
  }
  40% {
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translate3d(-20vw, -45vw, 0) rotate(-30deg) scale(1.1);
    opacity: 0.75;
  }
  100% {
    transform: translate3d(-100vw, -100vw, 0) rotate(-45deg) scale(1.4);
    opacity: 0;
  }
}

@keyframes baseMoveOut {
  0% {
    transform: translate3d(0, 0, 0) rotate(0) scale(1);
    opacity: 1;
  }
  40% {
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translate3d(-15vw, 10vw, 0) rotate(30deg) scale(1.05);
    opacity: 0.75;
  }
  100% {
    transform: translate3d(-100vw, 100vw, 0) rotate(45deg) scale(1.4);
    opacity: 0;
  }
}

// CERC ALBASTRU INCHIS
@keyframes confetti1MoveOut {
  0% {
    transform: translate3d(0, 0, 0) rotate(0) scale(0.05);
    opacity: 1;
  }

  25% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate3d(10vw, -8vh, 0) rotate(0) scale(0.05);
    opacity: 1;
  }

  100% {
    transform: translate3d(20vw, -16vh, 0) scale(0.1);
    opacity: 0;
  }
}
// CERC ALBASTRU INCHIS
@keyframes confetti2MoveOut {
  0% {
    transform: translate3d(0, 0, 0) rotate(0) scale(0.05);
    opacity: 1;
  }

  25% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate3d(14vw, 6vh, 0) rotate(0) scale(0.05);
    opacity: 1;
  }

  100% {
    transform: translate3d(25vw, 10vh, 0) scale(0.1);
    opacity: 0;
  }
}
// CERC ALBASTRU
@keyframes confetti3MoveOut {
  0% {
    transform: translate3d(0, 0, 0) rotate(0) scale(0.08);
    opacity: 1;
  }

  25% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate3d(-18vw, 1vh, 0) rotate(0) scale(0.08);
    opacity: 1;
  }

  100% {
    transform: translate3d(-70vw, 3vh, 0) scale(0.15);
    opacity: 0;
  }
}
// CERC ALBASTRU
@keyframes confetti4MoveOut {
  0% {
    transform: translate3d(0, 0, 0) rotate(0) scale(0.08);
    opacity: 1;
  }

  25% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate3d(30vw, 15vh, 0) rotate(0) scale(0.08);
    opacity: 1;
  }

  100% {
    transform: translate3d(40vw, 25vh, 0) scale(0.15);
    opacity: 0;
  }
}
// CONFETTI ALBASTRU
@keyframes confetti5MoveOut {
  0% {
    transform: translate3d(0, 0, 0) rotate(0) scale(0.15);
    opacity: 1;
  }

  25% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate3d(-30vw, -5vh, 0) rotate(-15deg) scale(0.15);
    opacity: 1;
  }

  100% {
    transform: translate3d(-40vw, -20vh, 0) rotate(-30deg) scale(0.23);
    opacity: 0;
  }
}
// CONFETTI GALBEN
@keyframes confetti6MoveOut {
  0% {
    transform: translate3d(0, 0, 0) rotate(0) scale(0.12);
    opacity: 1;
  }

  25% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate3d(5vw, -15vh, 0) rotate(15deg) scale(0.12);
    opacity: 1;
  }

  100% {
    transform: translate3d(8vw, -20vh, 0) rotate(45deg) scale(0.2);
    opacity: 0;
  }
}
// CONFETTI GALBEN
@keyframes confetti7MoveOut {
  0% {
    transform: translate3d(0, 0, 0) rotate(0) scale(0.1);
    opacity: 1;
  }

  25% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate3d(30vw, 30vh, 0) rotate(60deg) scale(0.1);
    opacity: 1;
  }

  100% {
    transform: translate3d(40vw, 60vh, 0) rotate(90deg) scale(0.15);
    opacity: 0;
  }
}
// CONFETTI ROSU
@keyframes confetti8MoveOut {
  0% {
    transform: translate3d(0, 0, 0) rotate(0) scale(0.2);
    opacity: 1;
  }

  25% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate3d(-10vw, -2vh, 0) rotate(-80deg) scale(0.2);
    opacity: 1;
  }

  100% {
    transform: translate3d(-20vw, -8vh, 0) rotate(-130deg) scale(0.3);
    opacity: 0;
  }
}
// CONFETTI ROSU
@keyframes confetti9MoveOut {
  0% {
    transform: translate3d(0, 0, 0) rotate(0) scale(0.2);
    opacity: 1;
  }

  25% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate3d(15vw, -25vh, 0) rotate(20deg) scale(0.2);
    opacity: 1;
  }

  100% {
    transform: translate3d(25vw, -50vh, 0) rotate(45deg) scale(0.32);
    opacity: 0;
  }
}
// CONFETTI VERDE
@keyframes confetti10MoveOut {
  0% {
    transform: translate3d(0, 0, 0) rotate(0) scale(0.15);
    opacity: 1;
  }

  25% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate3d(15vw, 2vh, 0) rotate(5deg) scale(0.15);
    opacity: 1;
  }

  100% {
    transform: translate3d(20vw, 4vh, 0) rotate(45deg) scale(0.32);
    opacity: 0;
  }
}

@keyframes challengeBounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
