div.challenge-timeline-wrapper {
  width: 100%;
  & > h3 {
    font-family: Koulen;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffa300;
    margin-bottom: 20px;
  }

  & > div.progress-bar-outside {
    width: 100%;
    height: 15px;
    border-radius: 7px;
    background: #ffa3003d;

    position: relative;

    & > div.progress-bar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-radius: 7px;
      background: #ffa300;
    }

    margin-bottom: 10px;
  }

  & > div.progress-bar-times {
    display: flex;
    justify-content: space-between;

    & > span {
      font-family: Indie Flower;
      font-size: 20px;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
    }
  }

  &.has_notification {
    @media (max-height: 700px) {
      & > h3 {
        font-size: 19px;
        line-height: 32px;
      }

      & > div.progress-bar-outside {
        margin-bottom: 6px;
      }
    }
  }
}
