input.form-input-text {
  border: 1px solid #ffffff;
  border-radius: 30px;

  color: #ffffff;

  font-family: Quicksand;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 20px;

  height: 40px;
  background-color: transparent;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  &.auth_code {
    color: #ffa300;
    border: 1px solid #ffa300;
    height: 60px;
    font-size: 20px;

    text-align: center;
    padding-left: 0;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-family: Quicksand;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffa30055;
    }
  }
}
