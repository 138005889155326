div.bottom-menu {
  bottom: 2vh;
  left: 10vw;
  right: 10vw;

  z-index: 25;

  @media (max-height: 700px) {
    bottom: 1vh;
  }

  &-info {
    bottom: 9vh;
    left: 10vw;

    z-index: 25;
  }
}
