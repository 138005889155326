div.settings-items-wrapper {
  top: 20vh;
  left: 10vw;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & > a,
  p {
    text-decoration: none;
    font-family: Quicksand;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;

    margin-bottom: 35px;
  }
}
