.learn-article-wrapper {
  top: 8vh;
  bottom: 5vh;
  left: 9vw;
  right: 9vw;

  & > img {
    width: 100%;
  }

  & > h1 {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #00ffc2;

    margin-top: 30px;
    margin-bottom: 30px;
  }

  & > p {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: justify;
    color: white;
  }
}
