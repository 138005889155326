.account-register-form {
  top: 15vh;
  left: 8vw;
  right: 8vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.location-selector-wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > .location-selector {
    top: 30vh;
    left: 20vw;
    right: 20vw;

    & > ul {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 15px;
      list-style-type: none;

      & > li {
        position: relative;
        font-family: Quicksand;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: white;

        & > span.radio-wrapper {
          position: absolute;
          left: -50px;
          width: 30px;
          top: 0px;
          bottom: 0px;

          display: flex;
          justify-content: center;
          align-items: center;

          & > span.radio {
            width: 25px;
            height: 25px;

            border: 2px solid white;
            border-radius: 12.5px;

            display: flex;
            justify-content: center;
            align-items: center;

            & > span.interior {
              width: 15px;
              height: 15px;
              border-radius: 10px;
            }
          }

          &.checked {
            & > span.radio {
              border: 2px solid #00ffc2;

              & > span.interior {
                background-color: #00ffc2;
              }
            }
          }
        }

        &:has(span.checked) {
          color: #00ffc2;
        }
      }
    }
  }

  .button-save-location {
    bottom: 20vh;
    left: 20vw;
    right: 20vw;
  }
}

.read-tc-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;

  & > .checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #00ffc2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: #00ffc2;
    font-size: 18px;
    &.active {
      border: 2px solid #00ffc2;
    }
  }

  & > .text {
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    color: white;

    & > a {
      color: white;
    }
  }
}
