div.delete-account-options-wrapper {
  bottom: 30vh;

  left: 20vw;
  right: 20vw;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 30px;

  & > a,
  & > p {
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: underline;
    color: #ffffff;
    &.delete {
      color: #e8205c;
    }
  }
}
