div.finalize-challenge-wrapper {
  width: 50vw;

  @media (max-width: 300px) {
    width: 80vw;
  }

  & > div.finalize-challenge-image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    height: 200px;

    transform: translateY(15px);
  }

  &.has_notification {
    & > div.finalize-challenge-image-wrapper {
      height: 175px;
    }
    @media (max-height: 700px) {
      & > div.finalize-challenge-image-wrapper {
        height: 150px;
      }
    }

    @media (max-width: 300px) {
      & > div.finalize-challenge-image-wrapper {
        height: 140px;
      }
    }
  }
}
