.learn-scroll-content {
  top: 15vh;
  bottom: 10vh;
  left: 5vw;
  right: 5vw;

  @media (max-width: 300px) {
    left: 2vw;
    right: 2vw;
    bottom: 15vh;
  }

  @media (max-height: 750px) {
    left: 3vw;
    right: 3vw;
    bottom: 12.5vh;
  }
  @media (max-height: 700px) {
    left: 2vw;
    right: 2vw;
    bottom: 15vh;
  }
}
