form.account-modify-form {
  top: 15vh;
  left: 8vw;
  right: 8vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

div.account-privacy-wrapper {
  top: 55vh;
  left: 8vw;
  right: 8vw;
  bottom: 18vh;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
div.account-privacy-wrapper > .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}
div.account-privacy-wrapper > .info > img {
  width: 20px;
  height: 20px;
}
div.account-privacy-wrapper > .info > .title {
  font-family: Quicksand;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
div.account-privacy-wrapper > .info > .info {
  font-family: Quicksand;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
}
div.account-privacy-wrapper > .form {
  display: flex;
  width: 85%;
  gap: 10px;
}
div.account-privacy-wrapper > .form > * {
  flex-basis: 1;
}
div.account-privacy-wrapper > .form > .info {
  font-family: Quicksand;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

div.account-delete-wrapper {
  bottom: 10vh;
  left: 8vw;
  right: 8vw;
  text-align: center;
}
div.account-delete-wrapper > a {
  text-decoration: underline;
  font-family: Quicksand;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #e8205c;
}

.notifications-disabled-icon {
  filter: brightness(0) saturate(100%) invert(22%) sepia(85%) saturate(3913%) hue-rotate(330deg) brightness(94%) contrast(93%);
  width: 30px;
  height: auto;
  right: 10px;
  top: 10px;
  z-index: 100;
}

