@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    transform: scaleY(1) translateY(0);
    margin-top: 10px;
    min-height: 40px;
    line-height: 13px;
    opacity: 1;
  }

  100% {
    opacity: 0;
    margin-top: 0;
    min-height: 0px;
    transform: scaleY(0) translateY(40px);
    line-height: 0px;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(40px);
  }
}

.notification-wrapper {
  position: fixed;
  left: 10vw;
  right: 10vw;
  bottom: 10vh;

  display: flex;
  flex-direction: column;

  & > div {
    position: relative;
    background-color: #2a294e;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;

    font-family: Quicksand;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
    min-height: 40px;
    position: relative;
    border-radius: 20px;

    padding-left: 10px;
    padding-right: 30px;
    margin-top: 10px;

    &.disappearing {
      animation: disappear 1s forwards ease;
    }

    animation: slideIn 0.75s forwards ease;

    & > .absolute {
      bottom: 1px;
      left: 13px;
      height: 1px;
      background-color: blue;
    }

    & > .dismiss-button {
      position: absolute;
      right: 10px;
      top: 0px;
      bottom: 0px;
      display: flex;
      align-items: center;
      flex-direction: flex-end;
    }
  }
}

.persistent-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  padding: 3em;

  z-index: 1000;

  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
  gap: 1em;

  & h1 {
    font-family: Koulen;
    font-size: 48px;
    font-weight: 400;
    line-height: 60px;
    text-align: center;
    color: #e8205c;
  }

  & img {
    width: 40vw;
    height: auto;
  }

  & p {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    color: white;

    & .emphasize {
      color: #00ffc2;
      font-weight: 700;
    }
  }
}
