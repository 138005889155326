div.privacy-options-wrapper {
  top: 35vh;
  left: 12vw;
  right: 12vw;
  height: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  & > .half {
    flex-basis: 50%;
  }

  & > .full {
    flex-basis: 100%;
    padding: 10px;
  }

  & > .option-title {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;

    padding-left: 10px;
  }

  & > .option-button {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }

  & > .option-description {
    font-family: Quicksand;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff77;
  }
}
