div.logo-bottom-wrapper {
  bottom: 5vh;
  left: 0;
  right: 0;
  height: 20vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 10px;

  transition: opacity 0.5s;

  @media (max-height: 700px) {
    bottom: 2vh;
    height: 20vh;
  }

  & > img:first-child {
    height: 80%;
  }
  & > img:last-child {
    height: 20%;
  }

  & > span > span:first-child {
    font-family: Raleway;
    font-size: 32px;
    font-weight: 300;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;

    color: #28bfa6;
  }

  & > span > span:last-child {
    font-family: Raleway;
    font-size: 32px;
    font-weight: 900;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;

    color: #28bfa6;
  }

  @media (max-height: 550px) {
    opacity: 0;
    transition: opacity 0.5s;
  }
}
