textarea.form-input-textarea {
  border: 1px solid #ffffff;
  border-radius: 30px;

  color: #ffffff;

  font-family: Quicksand;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 20px;
  padding-top: 10px;

  height: 40vh;
  background-color: transparent;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 1; /* Firefox */
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  &::-ms-input-placeholder {
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
}
