.progress-calendar-wrapper-wrapper {
  top: 30vh;
  bottom: 15vh;
  left: 10vw;
  right: 10vw;

  @media (max-width: 300px) {
    left: 5vw;
    right: 5vw;
  }

  &.has-notification {
    top: 40vh;
  }

  & > .progress-calendar-wrapper {
    height: 90%;
    width: 100%;
  }

  @media (max-height: 700px) {
    bottom: 17vh;
  }

  @media (max-height: 600px) {
    bottom: 19vh;
  }

  @media (max-height: 500px) {
    bottom: 22vh;
  }
}

.progress-challenges-completed {
  height: 10%;
  width: 100%;
  font-family: Koulen;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffa300;

  display: flex;
  justify-content: center;
  align-items: center;
}
