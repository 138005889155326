div.challenge-completed-rating-wrapper {
  top: 55vh;
  left: 10vw;
  right: 10vw;

  & > p {
    font-family: Indie Flower;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    color: #00ffc2;
  }

  & > div.picker {
    display: flex;
    width: 100%;
    justify-content: space-between;

    & img {
      width: min(15vw, 90px);
      height: min(15vw, 90px);
      &:not(.active) {
        filter: invert(82%) sepia(0%) saturate(0%) hue-rotate(148deg)
          brightness(86%) contrast(82%);
      }
    }
  }
}
