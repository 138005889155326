div.authenticate-mascot {
  bottom: 0px;
  height: 30vh;
  display: flex;
  justify-content: center;
  width: 100vw;
}

.login-send-password-button {
  top: 40vh;
  left: 10vw;
  right: 10vw;

  @media (max-height: 700px) {
    top: 42vh;
  }
}

.login-send-form-wrapper {
  top: 32vh;
  left: 10vw;
  right: 10vw;
}
