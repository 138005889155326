.form-radio {
  width: 50px;
  height: 30px;

  &:not(.active) {
    background-color: #e6e0e9;
    border: 2px solid #79747e;

    transition: background-color 0.2s ease-out, border 0.2s ease-out;
  }

  &.active {
    background-color: #00ffc2;
    border: 2px solid #00ffc2;

    transition: background-color 0.2s ease-out, border 0.2s ease-out;
  }

  border-radius: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > .circle {
    z-index: 2;
    height: 23px;
    width: 23px;
    border-radius: 23px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > .circle:not(.active) {
    background-color: #79747e;
    transform: translateX(2px);

    transition: transform 0.2s ease-out, background-color 0.2s ease-out;
  }

  & > .circle.active {
    background-color: white;
    transform: translateX(22px);

    transition: transform 0.2s ease-out, background-color 0.2s ease-out;
  }
}
