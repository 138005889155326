div.calendar-wrapper-5 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;

  & > div.calendar-element {
    width: 12vw;
    height: 12vw;

    border-radius: 12vw;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #ffa3003d;

    font-family: Indie Flower;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;

    color: white;
    margin: 2vw;

    @media (max-width: 300px) {
      width: 15vw;
      height: 15vw;
      margin: 1vw;
    }

    &.active {
      background-color: #ffa300;
    }

    &.disabled {
      background-color: #ffcf7d40;
    }

    &.streak {
      background-color: #00ffc2;
    }
  }
}

div.calendar-wrapper-7 {
  display: flex;
  flex-direction: column-reverse;

  & > div.group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    & > div.calendar-element {
      width: 13vw;
      height: 13vw;

      border-radius: 12vw;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #ffa3003d;

      font-family: Indie Flower;
      font-size: 20px;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: center;

      color: white;
      margin: 3.5px 3.5vw;

      @media (max-width: 300px) {
        width: 15vw;
        height: 15vw;
        margin: 3.5px 3.5vw;
      }

      &.active {
        background-color: #ffa300;
      }

      &.disabled {
        background-color: #ffcf7d40;
      }

      &.streak {
        background-color: #00ffc2;
      }
    }
  }
}

.calendar-element .month {
  font-size: 10px;
}

.calendar-element .feedback {
  width: 80%;

  //filter: brightness(0) saturate(100%) invert(82%) sepia(0%) saturate(57%)
  //hue-rotate(215deg) brightness(93%) contrast(89%);
}
