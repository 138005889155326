.menu-item > p {
  font-family: Indie Flower;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-top: 5px;

  &.active {
    color: #00ffc2;
  }
}
